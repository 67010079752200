import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './DocumentMessageItem.scss';
import { Document, Page, pdfjs } from 'react-pdf';
import Typography from '../../../../view/components/Typography';
import TextMessageItem from '../TextMessageItemV2';
import Loader from '../../../../view/components/Loader';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import pdfIcon from '../../../../assets/failedPDF.svg';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

interface IDocumentmsgProps {
  docUrl: string;
  docName: string;
  body?: string;
  source: string;
}

function DocumentMessageItem({
  docUrl,
  docName,
  body,
  source,
}: IDocumentmsgProps) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [numOfPages, setNumOfPages] = React.useState<number>();
  const ref = React.useRef<HTMLCanvasElement>(null);

  const handleDocClick = () => {
    track(EventNames.messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.clickAttachment,
      eventLabel: 'pdf',
    });
    window.open(docUrl, '_blank', 'noreferrer');
  };

  return (
    <section className={`document-wrapper ${source}`}>
      <figure className={`figure-wrapper ${source}`} onClick={handleDocClick}>
        <Document
          className="pdf-thumbnail"
          file={docUrl}
          onLoadSuccess={({ numPages }) => setNumOfPages(numPages)}
          loading={
            <Loader
              useFullPage={false}
              dimension={{ height: 112, width: 190 } as DOMRect}
            />
          }
          error={
            <img
              className="fallback-pdf"
              src={pdfIcon}
              alt="pdf fallback icon"
            />
          }
        >
          <Page
            width={190}
            canvasRef={ref}
            pageNumber={1}
            onLoadSuccess={() => {
              if (ref.current) {
                ref.current.style.height = '112px';
                ref.current.style.borderRadius = '8px 8px 0 0';
                ref.current.style.width = '190px';
              }
            }}
          />
        </Document>
        <section className="doc-details">
          <Typography
            size={10}
            weight="600"
            withColor={source === 'outbound' ? '#fff' : '#48515A'}
          >
            {t(docName)}
          </Typography>
          {!!numOfPages && (
            <Typography
              size={8}
              withColor={source === 'outbound' ? '#fff' : '#48515A'}
            >
              {numOfPages} {numOfPages === 1 ? t('PAGE') : t('PAGES')} · PDF
            </Typography>
          )}
        </section>
      </figure>
      {!!body && (
        <section className="attached-msg">
          <TextMessageItem source={source} message={body} />
        </section>
      )}
    </section>
  );
}
export default DocumentMessageItem;
