import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import { useChatBroker } from '../../../../../chat-module/broker/useChatBroker';
import {
  MessageSendType,
  MessageChannelTypes,
} from '../../../../../chat-module/constants/CommonConstants';
import {
  EventNames,
  EventActions,
  EventCategories,
  EventSource,
} from '../../../../../shared/constant/Analytics';
import { SOSUrls } from '../../../../../shared/constant/ExternalURLs';
import { IClientSummary } from '../../../../../shared/types/response/provider';
import sosRequestTracker from '../../../../../utilities/hooks/sosRequestTracker/sosRequestTracker';
import useTracking from '../../../../../utilities/hooks/useTracking';
import AssignClientListItem from './AssignClientListItem';
import NoResultsFound from '../../../../../view/app-components/NoResultsFound';
import Loader from '../../../../../view/components/Loader';
import { Button } from '../../../components/Button';
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg';
import Modal from '../../../components/Modal';
import { getClientsForAssignment } from '../../../../../shared/redux/actions/provider';
import { useFetch } from '../../../../../utilities/hooks/useFetch';
import { Input } from '../../../components/Input';

interface ISosClientList {
  open: boolean;
  handleClose: () => void;
}

function SosClientList({ open, handleClose }: ISosClientList) {
  const { t } = useTranslation();
  const { track } = useTracking();

  const dispatch = useDispatch();
  const { logSOSEvent } = sosRequestTracker();

  const { data: clientList, isLoading: clientListLoading } = useFetch<
    IClientSummary[]
  >(['clients'], () => dispatch(getClientsForAssignment()));

  const [searchText, setSearchText] = React.useState<string>('');
  const [assignedClients, setAssignedClients] = React.useState<
    IClientSummary[]
  >([]);

  const filteredClients = clientList?.filter((c) =>
    c.user.friendlyName?.toLowerCase().includes(searchText.toLowerCase()),
  );

  const onCheckboxClick = (client: IClientSummary, isChecked: boolean) => {
    if (isChecked) {
      const newAssignedClients = [...assignedClients];
      newAssignedClients.push(client);
      setAssignedClients(newAssignedClients);
    } else {
      const newAssignedClients = assignedClients.filter(
        ({ userId }) => userId !== client.userId,
      );
      setAssignedClients(newAssignedClients);
    }
  };
  const onSOSSuccess = () => {
    const successMsg =
      assignedClients.length > 1
        ? t('SOS_NEW_SUCCESS_PROMPT_MULTIPLE')
        : t('SOS_NEW_SUCCESS_PROMPT_SINGLE');
    toast.success(successMsg);
    handleClose();
  };
  const { sendMessage } = useChatBroker(onSOSSuccess);

  const onConfirm = () => {
    track(EventNames.sosHelpLine, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.confirm,
      featureVersion: 'v1',
      eventLabel: 'confirm',
      eventSource: EventSource.quickTab,
      eventValue: assignedClients.length,
    });
    const logPayload = assignedClients.map((item) => ({
      userId: item.userId,
      providerId: item.providerId,
      providerRole: item.providerRole,
    }));
    logSOSEvent(logPayload);
    assignedClients.forEach((item) => {
      sendMessage({
        contentToSend: {
          data: 'This message contains required information for emergency purpose. Please click to get more information.',
          type: MessageSendType.DEEPLINK,
          deepLink: SOSUrls.emergencySOS,
        },
        conversationId: item.conversationId as string,
        channelType: item.channelType as MessageChannelTypes,
      });
    });
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={t('SELECT_CLIENTS_SOS')}
    >
      <Input
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        startIcon={<SearchIcon />}
        placeholder={t('searchNames')}
        containerClassName="w-full mb-3"
      />
      <section className="overflow-scroll h-[50vh] w-[512px] ">
        {clientListLoading && (
          <Loader
            useFullPage={false}
            dimension={{ height: 400, width: 500 } as DOMRect}
          />
        )}
        {!clientListLoading && !filteredClients?.length && (
          <div className="empty-results">
            <NoResultsFound
              captionCopy={
                searchText && !filteredClients?.length
                  ? t('noResults')
                  : t('NO_CLIENTS_HEADER')
              }
              description={
                searchText && !filteredClients?.length
                  ? t('pleaseCheckKeywords')
                  : t('NO_CLIENTS_SUBHEADER')
              }
              width="100%"
            />
          </div>
        )}
        {filteredClients?.map((client, index) => (
          <AssignClientListItem
            isChecked={assignedClients.some((c) => c.userId === client.userId)}
            key={`${client.userId}-${client.conversationId}`}
            onClientCheckBoxChange={onCheckboxClick}
            clientDetails={client}
            index={index}
            isCheckBoxDisable={false}
          />
        ))}
      </section>
      <div className="text-gray-500 text-sm">
        {t('clientsSelected', { count: assignedClients.length })}
      </div>
      <Button
        className="w-full mt-3"
        disabled={!assignedClients.length}
        onClick={onConfirm}
      >
        {t('confirmButton')}
      </Button>
    </Modal>
  );
}

export default SosClientList;
