import { t } from 'i18next';

export enum ProviderRole {
  COACHING = '0',
  CLINICAL = '1',
  HELPLINE = '2',
  COACH_AND_CLINICAL = '3',
  CARENAVIGATOR = '4',
  NUTRITIONIST = '5',
  FITNESS = '6',
  FINANCIAL = '7',
  EXECUTIVE = '8',
  LEGAL = '9',
  CAREOFFSITE = '10',
  COACHOFFSTE = '11',
  ONSITE = '12',
}

// Not in use currently, in future it will be used
// that's why keeping separately here from above enum
export const ProviderCoachingOffsiteRole = '11';

export const dayOfWeekNumbers = ['0', '1', '2', '3', '4', '5', '6', '7'];

export const ProviderRoleLabels = {
  [ProviderRole.COACHING]: {
    label: t('coachingType'),
  },
  [ProviderRole.CLINICAL]: {
    label: t('clinical'),
  },
  [ProviderRole.HELPLINE]: {
    label: t('HELPLINE'),
  },
  [ProviderRole.CARENAVIGATOR]: {
    label: t('CARE_NAVIGATOR'),
  },
  [ProviderRole.NUTRITIONIST]: {
    label: t('nutritionist'),
  },
  [ProviderRole.FITNESS]: {
    label: t('fitness'),
  },
  [ProviderRole.FINANCIAL]: {
    label: t('financial'),
  },
  [ProviderRole.EXECUTIVE]: {
    label: t('executive'),
  },
  [ProviderRole.LEGAL]: {
    label: t('legal'),
  },
  [ProviderRole.CAREOFFSITE]: {
    label: t('IN_PERSON'),
  },
  [ProviderRole.ONSITE]: {
    label: t('ONSITE'),
  },
} satisfies Record<string, { label: string }>;

export enum InteractionType {
  Skipped = '0',
  Completed = '1',
}
