export enum RiskAssesment {
  NoRisk = 1,
  LowRisk = 2,
  MediumRisk = 3,
  HighRiskNonCritical = 5,
  HighRiskCritical = 4,
  NotAssessed = 6,
}

// intake - very first session
// followup - all other sessions after the intake happened
export enum CaseNoteFormType {
  v0 = '0', // old common form
  V1 = '1', // coaching
  V3 = '3', // care intake outside the session
  V4 = '4', // care followup outside the session
  V5 = '5', // coaching inside the session
  V6 = '6', // care intake inside the session
  V7 = '7', // care followup inside the session
}
export const SOS_GUIDELINES_DATA = {
  fields: [
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_ONE',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_TWO',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_THREE',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_FOUR',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_FIVE',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_SIX',
    'INTELLECT_SOS_GUIDELINES_BODY_SUBPOINT_SEVEN',
  ],
};
