import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Transition,
} from '@headlessui/react';
import { Fragment, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/ChevronRightV2.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/ChevronLeftV2.svg';

import { Button } from './Button';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  className?: string;
  showNavigation?: boolean;
  showCloseButton?: boolean;
  allowOutsideClick?: boolean;
  hideLeftNavigation?: boolean;
  hideRightNavigation?: boolean;
  handleNextClick?: () => void;
  handlePrevClick?: () => void;
  headerClassname?: string;
  bodyClassname?: string;
  iconClassname?: string;
}

export default function Modal({
  open,
  handleClose,
  title,
  subtitle,
  children,
  className,
  handleNextClick,
  handlePrevClick,
  showNavigation = false,
  showCloseButton = true,
  hideLeftNavigation = false,
  hideRightNavigation = false,
  allowOutsideClick = true,
  headerClassname,
  bodyClassname,
  iconClassname,
}: PropsWithChildren<ModalProps>) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        transition
        className="relative w-[50vw] z-30 transition duration-300 ease-out data-[closed]:opacity-0"
        onClose={() => allowOutsideClick && handleClose()}
      >
        <DialogBackdrop className="fixed inset-0 bg-black/30" />

        <div className="fixed inset-0 overflow-y-auto flex min-h-full items-center justify-center p-4 text-center">
          <DialogPanel
            className={twMerge('w-md max-w-[90vw] relative', className)}
          >
            <div className="flex items-center">
              {showNavigation && handlePrevClick ? (
                <Button
                  variant="secondary"
                  className={twMerge(
                    'mr-2 bg-gray-300 text-white bg-opacity-[32%] hover:bg-opacity-[48%] active:bg-opacity-[64%]',
                    hideLeftNavigation ? 'opacity-0 pointer-events-none' : '',
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePrevClick();
                  }}
                >
                  <ChevronLeftIcon />
                </Button>
              ) : null}
              <div
                className={twMerge(
                  'w-full max-h-[90vh] overflow-auto rounded-2xl bg-white text-left align-middle shadow-xl transition-all',
                  headerClassname,
                )}
              >
                <div
                  className={twMerge(
                    'flex gap-2 p-6 items-center justify-between sticky top-0 left-0 w-full bg-white',
                    bodyClassname,
                  )}
                >
                  <div className="w-full">
                    <DialogTitle
                      as="h3"
                      className="text-displayXs w-full flex items-center justify-between gap-3 font-semibold leading-7 text-gray-900 w-100"
                    >
                      <div>{title || null}</div>
                      {showCloseButton && (
                        <Button
                          variant="ghost"
                          className="w-8 h-8 p-2 focus:bg-gray-200"
                          onClick={handleClose}
                          autoFocus={false}
                        >
                          <CloseIcon className={twMerge('text-gray-600', iconClassname)} />
                        </Button>
                      )}
                    </DialogTitle>
                    {subtitle ? (
                      <div className="text-gray-600 mt-0.5 leading-5 text-sm">
                        {subtitle}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="p-6 pt-0 h-full w-full">{children}</div>
              </div>
              {showNavigation && handleNextClick ? (
                <Button
                  variant="secondary"
                  className={twMerge(
                    'ml-2 bg-gray-300 bg-opacity-[32%] text-white hover:bg-opacity-[48%] active:bg-opacity-[64%]',
                    hideRightNavigation ? 'opacity-0 pointer-events-none' : '',
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNextClick();
                  }}
                >
                  <ChevronRightIcon />
                </Button>
              ) : null}
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
}
