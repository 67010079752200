import { beautifyUrl } from '../../../utilities/common/Path';
import { ISaveCaseNotesReqType } from '../../../view/app-components/CaseNotesV2/types';
import Config from '../../Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

export const getNewCaseNotesQuestions = async (formType: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.caseNotes.newQuestions, [formType]),
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message);
};

export const getOptions = async (
  questionId: string,
  formType: string,
  role: string,
  meetingId?: string,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.caseNotes.newIssueOptions, [questionId]),
    {},
    { form: formType, role, meetingId },
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message);
};

export const getNewCaseNotesAnswers = async (meetingId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.caseNotes.newAnswers, [meetingId]),
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message);
};

export const saveCaseNotes = async (body: ISaveCaseNotesReqType) => {
  const response = await client.doPost(Config.api.caseNotes.newSave, body);
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.error?.message);
};

export const fetchCaseNoteFormType = async (
  meetingId: string,
  isInSession: boolean,
) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.caseNotes.formType, [meetingId]),
    {},
    { inSession: isInSession },
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message);
};

export const getInSessionCaseNotesAnswers = async (meetingId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.caseNotes.newAnswersInSession, [meetingId]),
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message);
};

export const getPrimaryIssueStatus = async (meetingId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.caseNotes.primaryIssueCheck, [meetingId]),
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message);
};
