import { useFetch, useMutation } from '../../../../../utilities/hooks/useFetch';
import {
  addRating,
  getSubmissionStatus,
} from '../../../../shared/service/API/npsScore';

interface ISubmissionStatusResp {
  submitted: boolean;
}

export function useFeedbackDetails(
  handleSuccess?: (response: ISubmissionStatusResp) => void,
  handleError?: () => void,
) {
  const {
    data: submissionStatus,
    isLoading: isSubmissionStatusLoading,
    refetch: refetchSubmissionStatus,
  } = useFetch<ISubmissionStatusResp>(['nps-status'], getSubmissionStatus, {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: addScore, isLoading: isAddScoreLoading } = useMutation(
    (payload: { score: number; interaction: string }) => addRating(payload),
    {
      onSuccess: (response: ISubmissionStatusResp) => {
        refetchSubmissionStatus();
        handleSuccess?.(response);
      },
      onError: handleError,
    },
  );

  return {
    addScore,
    isAddScoreLoading,
    submissionStatus,
    isSubmissionStatusLoading,
    refetchSubmissionStatus,
  };
}
