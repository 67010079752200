import Config from '../../../../shared/Config';
import NetworkClient from '../../../../shared/service/Network';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to fetch rating, please try again!',
};

export const addRating = async (payload: {
  score: number;
  interaction: string;
}) => {
  const response = await client.doPost(Config.apiV2.nps.addScore, payload);
  if (response && response?.data) {
    return response?.data;
  }
  throw new Error(errorMessages.generic);
};

export const getSubmissionStatus = async () => {
  const response = await client.doGet(Config.apiV2.nps.submissionCheck);
  if (response && response.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};
