import { useTranslation } from 'react-i18next';
import { SOS_GUIDELINES_DATA } from '../../shared/constant/Casenotes';
import Modal from '../components/Modal';

export interface IGuidelineModal {
  title: string;
  handleClose: () => void;
  open: boolean;
}
function GuidelineModal({ title, handleClose, open }: IGuidelineModal) {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={<h3 className="text-yellow-800 text-xl font-medium ">{title}</h3>}
      className="bg-yellow-50 border border-solid border-yellow-500 rounded-lg p-3"
      headerClassname="bg-yellow-50 shadow-none"
      bodyClassname="bg-yellow-50 p-2"
      iconClassname="text-gray-500"
    >
      <section className="min-w-[320px] md: w-[35vw]">
        <ul className="text-sm text-gray-700 list-disc">
          <li>
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: `${t('INTELLECT_SOS_GUIDELINES_BODY_ONE')}` }} className="[&>a>u]:text-primary-600 font-medium pointer-events-none"/>
          </li>
          <li>{t('INTELLECT_SOS_GUIDELINES_BODY_TWO')}</li>
          <ul className="list-disc ml-6">
            {SOS_GUIDELINES_DATA.fields.map((field) => (
              <li key={field}>{t(field)}</li>
            ))}
          </ul>
          <li className="mt-4">
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: `${t('INTELLECT_SOS_GUIDELINES_BODY_THREE')}` }} />
          </li>
        </ul>
      </section>
    </Modal>
  );
}
export default GuidelineModal;
